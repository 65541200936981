<template>
	<div class="vigilante">
		<w-navTab titleText="申请成为义警"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<div class="container">
			<template v-if="type == 1">
				<div class="nav">
					<w-title title="完善资料"></w-title>
				</div>
				<div class="middle-item">
					<div class="item-top">
						<div class="item-top-title">您的姓名：</div>
						<div class="item-top-text">{{ userInfo.name }}</div>
					</div>
					<div class="item-top">
						<div class="item-top-title">身份证号：</div>
						<div class="item-top-text">{{ userInfo.id_card }}</div>
					</div>
				</div>

				<div class="middle-item">
					<div class="middle-item-input" v-if="userInfo.role_type > 1">
						<div class="item-left">血型</div>
						<input type="text" @click="isBloodType = true" v-model="bloodTypeText" readonly
							placeholder="请选择血型" />
						<div class="middle-item-right" v-if="bloodTypeText == ''">
							<van-icon name="arrow-down" size="20px" color="#bbb" />
						</div>
					</div>
					<div class="middle-item-radio" v-if="userInfo.role_type > 1">
						<div class="item-left">是否党员</div>
						<div>
							<van-radio-group v-model="partyMember" direction="horizontal">
								<van-radio name="1">是</van-radio>
								<van-radio name="2">否</van-radio>
							</van-radio-group>
						</div>
					</div>
					<div class="middle-item-radio" v-if="userInfo.role_type > 1">
						<div class="item-left">是否退伍军人</div>
						<div>
							<van-radio-group v-model="veteran" direction="horizontal">
								<van-radio name="1">是</van-radio>
								<van-radio name="2">否</van-radio>
							</van-radio-group>
						</div>
					</div>
					<van-field v-if="userInfo.role_type > 1" v-model="height" label="身高(厘米)" placeholder="请输入身高"
						input-align="right" />
					<van-field v-if="userInfo.role_type > 1" v-model="weight" label="体重(公斤)" placeholder="请输入体重"
						input-align="right" />
					<van-field v-if="userInfo.role_type > 1" v-model="vocation" label="职业类型" placeholder="请输入职业类型"
						input-align="right" />
					<van-field v-model="company" label="工作单位" placeholder="请输入工作单位" input-align="right" />
					<van-field v-model="address" label="现住地址" placeholder="请输入现住地址" input-align="right" />
					<van-field v-model="skill" label="专业技能" placeholder="请输入专业技能" input-align="right" />

					<van-field v-model="urgentName" label="紧急联系人" placeholder="请输入紧急联系人" input-align="right" />
					<van-field v-model="urgentTelephone" label="紧急联系电话" placeholder="请输入紧急联系电话" input-align="right" />
					<van-field v-model="hobby" label="兴趣爱好" placeholder="请输入兴趣爱好" input-align="right" />

					<van-field v-model="referrer" label="推荐人姓名" placeholder="请输入推荐人姓名" input-align="right" />
					<van-field v-model="referrerTel" label="推荐人手机号" type="number" placeholder="请输入推荐人手机号"
						input-align="right" maxlength="11" />
				</div>
				<van-popup v-model="isBloodType" position="bottom">
					<van-picker show-toolbar value-key="department_name" :columns="columns" @confirm="onConfirm"
						@cancel="isBloodType = false" />
				</van-popup>
				<div class="middle-footer">
					<van-button v-if="districtType() == 1" type="primary" round color="#3377FF" block
						@click="onPoint">提交申请信息
					</van-button>
					<van-button v-if="districtType() == 2" type="primary" round color="#ff6969" block
						@click="onPoint">提交申请信息
					</van-button>
				</div>
			</template>
			<div class="content-fixed" v-else>
				<div class="content-fixed-icon">
					<img src="@/assets/img/u_icon117.png" alt="" v-if="type == 2">
					<img src="@/assets/img/u_icon118.png" alt="" v-else>
				</div>
				<div class="content-fixed-header" v-if="type == 2">
					<div class="fixed-header-title">正在审核中</div>
					<div class="fixed-header-text">
						您的信息已提交，预计需要<span>7个工作日</span>内完成，请耐心等待…
					</div>
				</div>
				<div class="content-fixed-header" v-if="type == 4">
					<div class="fixed-header-title title-active">审核失败</div>
					<div class="fixed-header-text" v-if="auditStatus.review_time">申请日期：{{auditStatus.review_time}}</div>
				</div>
				<div class="content-fixed-footer" v-if="type == 4">
					<div class="fixed-footer-title">失败原因：</div>
					<div class="fixed-footer-content" v-if="auditStatus.reason">
						{{auditStatus.reason}}
					</div>
				</div>
				<div class="content-fixed-bottom">
					<div class="fixed-bottom-title" v-if="type == 2">
						审核结果将会在站内信通知，请注意查收
					</div>
					<div class="fixed-bottom-text" v-if="type == 4">
						请再接再励！多参与志愿活动，参考失败原因进行改进。
						下次申请入口开放时间：<span>{{auditTime}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Vant from '@/vendor/vant.js';
	import UserApi from '@/api/user';
	import WTitle from '@/components/Title';
	import ValidatorVendor from '@/vendor/validator';
	import Moment from '@/vendor/moment';
	import LoadingPage from '@/components/LoadingPage';
	
	export default {
		inject: ['className', 'districtType', 'themeColor'],
		name: 'ApplyVigilante',
		data() {
			return {
				loadingPage: true,
				type: 1,
				checked: true,
				columns: ['A', 'B', 'AB', 'O', 'RH'],
				isBloodType: false,
				name: '',
				idCard: '',
				bloodType: '',
				height: '',
				weight: '',
				partyMember: "2",
				veteran: "2",
				vocation: '',
				company: '',
				address: '',
				skill: '',
				urgentName: '',
				urgentTelephone: '',
				hobby: '',
				validator: null,
				bloodTypeText: "",
				userInfo: "",
				referrer: '',
				referrerTel: '',
				auditStatus: '',
				auditTime: ''
			};
		},
		created() {
			this.getAuditStatus()
			UserApi.getUserBasicInfo()
				.then(result => {
					this.userInfo = result.data;
					this.bloodTypeText = result.data.blood_type

					if (this.userInfo.role_type == 2 && this.userInfo.love_value < 4500) {
						Vant.Dialog.alert({
							title: '申请提示',
							message: "您的爱心值不足4500，无法申请成为义警。",
							theme: 'round-button',
						}).then(() => {
							this.$router.go(-1)
						});
					}

					if (this.userInfo.role_type == 3) {
						Vant.Dialog.alert({
							title: '申请提示',
							message: "您已经是义警了,请不要重复申请！",
							theme: 'round-button',
						}).then(() => {
							this.$router.go(-1)
						});
					}
				})
				.catch(error => {
					console.log(error)
				});

			UserApi.getUserInfo()
				.then(result => {
					this.height = result.data.height || '';
					this.weight = result.data.weight || '';
					this.partyMember = result.data.party_member ? result.data.party_member.toString() : '2';
					this.veteran = result.data.veteran ? result.data.veteran.toString() : '2';
					this.vocation = result.data.job;
					this.company = result.data.company;
					this.address = result.data.address;
					this.skill = result.data.specialty;
					this.urgentName = result.data.emergency_contact;
					this.urgentTelephone = result.data.emergency_telephone;
					this.hobby = result.data.hobby;
					this.bloodType = result.data.blood_type
				})
				.catch(error => {
					console.log(error);
				})

			this.validator = new ValidatorVendor({
				rules: {
					company: {
						required: true
					},
					address: {
						required: true
					},
					specialty: {
						required: true
					},
					emergency_contact: {
						required: true
					},
					emergency_telephone: {
						required: true,
						mobile: true
					},
					hobby: {
						required: true
					},
				},
				messages: {
					company: {
						required: '工作单位不能为空'
					},
					address: {
						required: '现住地址不能为空'
					},
					specialty: {
						required: '专业技能不能为空'
					},
					emergency_contact: {
						required: '紧急联系人不能为空'
					},
					emergency_telephone: {
						required: '紧急联系电话不能为空',
						mobile: '手机号格式错误'
					},
					hobby: {
						required: '兴趣爱好不能为空'
					},
				}
			});

			if (this.userInfo.role_type == 2) {
				this.validator.addRules({
					rules: {
						blood_type: {
							required: true
						},
						height: {
							required: true
						},
						weight: {
							required: true
						},
						job: {
							required: true
						},
						referrer: {
							required: true
						},
						referrer_tel: {
							required: true,
							mobile: true
						}
					},
					messages: {
						blood_type: {
							required: '血型不能为空'
						},
						height: {
							required: '身高（厘米）不能为空'
						},
						weight: {
							required: '体重（公斤）不能为空'
						},
						job: {
							required: '职业类型不能为空'
						},
						referrer: {
							required: '推荐人姓名不能为空'
						},
						referrer_tel: {
							required: '推荐人手机号不能为空',
							mobile: '手机号格式错误'
						}
					}
				})
			}

		},
		methods: {
			onPoint() {
				let params = {
					role_type: this.userInfo.role_type + 1,
					blood_type: this.bloodType,
					height: this.height,
					weight: this.weight,
					party_member: this.partyMember,
					veteran: this.veteran,
					job: this.vocation,
					company: this.company,
					address: this.address,
					specialty: this.skill,
					emergency_contact: this.urgentName,
					emergency_telephone: this.urgentTelephone,
					hobby: this.hobby,
					referrer: this.referrer,
					referrer_tel: this.referrerTel,
				}

				if (!this.validator.checkData(params)) {
					return;
				}
				UserApi.apply(params)
					.then(result => {
						Vant.Dialog.alert({
							title: '提交申请成功',
							message: result.msg
						}).then(() => {
							this.type = 2
						});
					})
					.catch(error => {
						Vant.Toast(error.msg);
					})

			},
			onConfirm(index, key) {
				this.bloodTypeText = index
				this.bloodType = key + 1
				this.isBloodType = false
			},
			getAuditStatus() {
				UserApi.auditStatus()
					.then(result => {
						this.auditStatus = result.data
						this.type = result.data.audit_status
						
						
						if(result.data.review_time){
							this.auditTime = Moment(result.data.review_time).add(1, 'days').format('YYYY/MM/DD HH:mm:ss')
							
							let time = Moment(result.data.review_time).add(1, 'days')
							if(Moment().unix() > Moment(time).unix()){
								this.type = 1
							}
						}
						this.loadingPage = false
					})
					.catch(error => {
						console.log(error)
					});
			}

		},
		components: {
			WTitle,
			LoadingPage
		}
	};
</script>

<style lang="less" scoped>
	.vigilante {
		position: relative;
		overflow: hidden;

		.container {
			padding: 0 10px 64px 10px;
			box-sizing: border-box;

			.nav {
				padding: 13px 0 12px 10px;
			}

			.middle-item {
				background: #ffffff;
				border-radius: 8px;
				overflow: hidden;
				margin-bottom: 10px;

				.van-cell {
					font-size: 16px;
				}

				.item-top {
					display: flex;
					justify-content: space-between;
					border-bottom: 1px solid #eee;
					margin: 0 15px;
					padding: 9px 0;
					align-items: center;

					.item-top-title {
						font-size: 16px;
						color: #646566;
						line-height: 25px;
					}

					.item-top-text {
						font-size: 16px;
						line-height: 25px;
						color: #333;
					}
				}

				.item-top:last-child {
					border-bottom: 0;
				}
			}

			.middle-item-radio {
				display: flex;
				justify-content: space-between;
				border-bottom: 1px solid #eee;
				align-items: center;
				margin: 0 15px;
				padding: 11px 0;
				font-size: 16px;
				color: #646566;
				line-height: 25px;
			}

			.middle-footer {
				width: 100%;
				position: absolute;
				padding-right: 20px;
				bottom: 20px;
				box-sizing: border-box;

				/deep/.van-button--round {
					border-radius: 8px;
					font-size: 18px;
				}
			}

			.middle-item-input {
				position: relative;
				display: flex;
				justify-content: space-between;
				border-bottom: 1px solid #eee;
				align-items: center;
				margin: 0 15px;

				.item-left {
					font-size: 16px;
					color: #646566;
					line-height: 25px;
				}

				input {
					border: 0;
					font-size: 16px;
					line-height: 25px;
					color: #333;
					text-align: right;
					padding: 5px 18px 8px 0;
					margin-top: 5px;
					box-sizing: border-box;
					position: relative;
					z-index: 10;
					background-color: transparent;

					&:focus {
						border-bottom-color: #3377ff;
					}

					&[readonly]:focus {
						border-bottom-color: #eee;
					}
				}

				.middle-item-right {
					position: absolute;
					right: 0;
					top: 13px;
				}
			}
			
			.content-fixed {
				width: 100%;
				min-height: calc(100vh - 46px);
				padding-top: 95px;
				position: fixed;
				top: 47px;
				left: 0;
				background-color: #fff;
				box-sizing: border-box;
				
				.content-fixed-icon {
					width: 106px;
					height: 106px;
					margin: 0 auto;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}

				.content-fixed-header {
					padding: 0 60px;
					box-sizing: border-box;

					.fixed-header-title {
						color: #FFB23F;
						text-align: center;
						font-size: 20px;
						font-weight: bold;
						margin-top: 48px;

						&.title-active {
							color: #F76356 !important;
						}
					}

					.fixed-header-text {
						color: #999;
						text-align: center;
						font-size: 15px;
						margin-top: 16px;
						line-height: 23px;

						span {
							color: #37F;
						}
					}
				}

				.content-fixed-footer {
					margin: 28px 16px 0;

					.fixed-footer-title {
						color: #4E5969;
						font-size: 14px;
						line-height: 22px;
					}

					.fixed-footer-content {
						color: #FF6969;
						font-size: 15px;
						line-height: 23px;
						border-radius: 4px;
						background: #F2F2F2;
						padding: 8px 13px;
						box-sizing: border-box;
						margin-top: 8px;
					}
				}

				.content-fixed-bottom {
					width: 100%;
					position: fixed;
					left: 0;
					bottom: 90px;

					.fixed-bottom-title {
						color: #999;
						text-align: center;
						font-size: 14px;
					}

					.fixed-bottom-text {
						margin: 0 12px;
						color: #999;
						font-size: 14px;
						line-height: 22px;

						span {
							color: #37F;
						}
					}
				}
			}
		}
	}
</style>